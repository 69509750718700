import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/jwt.service';
import router from '@/router';
import store from '@/core/services/store';
import { Promise } from 'core-js';
import { LOGOUT } from '@/core/services/store/auth.module';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = 'https://api.zenofontours.com/admin/v1/';
    // Vue.axios.defaults.baseURL = 'https://api.zenofontours.com/admin/_v1/';
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      'Authorization'
    ] = `${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    this.setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(`${resource}`)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          if (error.response.status == 403) {
            store.dispatch(LOGOUT);
            router.push({ name: 'login' });
          } else if (error.response.status == 404) {
            router.push({ name: '404' });
          } else {
            reject(error);
          }
        });
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    this.setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(`${resource}`, params)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          if (error.response.status == 403) {
            store.dispatch(LOGOUT);
            router.push({ name: 'login' });
          } else if (error.response.status == 404) {
            router.push({ name: '404' });
          } else {
            reject(error);
          }
        });
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
