import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
//import MockService from '@/core/mock/mock.service';
import { VERIFY_AUTH } from '@/core/services/store/auth.module';
//import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/metronic';
import '@/core/plugins/treeselect';
import '@/core/plugins/flag';
import '@mdi/font/css/materialdesignicons.css';
import * as VueGoogleMaps from 'vue2-google-maps';

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  //store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDpGjT9ygaW_QY-af26iEUF1Rhs8cMERSY',
    libraries: 'places',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});

Vue.prototype.$MEDIA_DEV_ENV = "https://media.zenofontours.com/demo/";
Vue.prototype.$MEDIA_PROD_ENV = "https://media.zenofontours.com/";
Vue.prototype.$API_DEV_ENV = "https://api.zenofontours.com/admin/_v1/";
Vue.prototype.$API_PROD_ENV = "https://api.zenofontours.com/admin/v1/";

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
