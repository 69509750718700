import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/destination",
          name: "destination",
          component: () => import("@/view/pages/destination/Destination.vue"),
          children: [
            {
              path: "listings",
              name: "destinations",
              component: () => import("@/view/pages/destination/Listings.vue")
            },
            {
              path: "listings/:id",
              name: "show-destination",
              component: () => import("@/view/pages/destination/Profile.vue")
            },
            {
              path: "create",
              name: "create-destination",
              component: () => import("@/view/pages/destination/Create.vue")
            }
          ]
        },
        {
          path: "/attraction",
          name: "attraction",
          component: () => import("@/view/pages/attraction/Attraction.vue"),
          children: [
            {
              path: "listings/:destination",
              name: "attractions",
              component: () => import("@/view/pages/attraction/Listings.vue")
            },
            {
              path: "listings/:id",
              name: "show-attraction",
              component: () => import("@/view/pages/attraction/Profile.vue")
            },
            {
              path: "create/:destination?",
              name: "create-attraction",
              component: () => import("@/view/pages/attraction/Create.vue")
            }
          ]
        },
        {
          path: "/category",
          name: "category",
          component: () => import("@/view/pages/category/Category.vue"),
          children: [
            {
              path: "listings",
              name: "categories",
              component: () => import("@/view/pages/category/Listings.vue")
            },
            {
              path: "listings/:id",
              name: "show-category",
              component: () => import("@/view/pages/category/Profile.vue")
            },
            {
              path: "create",
              name: "create-category",
              component: () => import("@/view/pages/category/Create.vue")
            }
          ]
        },
        {
          path: "/experience",
          name: "experience",
          component: () => import("@/view/pages/experience/Experience.vue"),
          children: [
            {
              path: "create/:id",
              name: "create-experience",
              component: () => import("@/view/pages/experience/Create.vue")
            },
            {
              path: "listings",
              name: "experiences",
              component: () => import("@/view/pages/experience/Listings.vue")
            },
            {
              path: "listings/:id",
              name: "show-experience",
              component: () => import("@/view/pages/experience/Profile.vue")
            },
            {
              path: "calendar/:id",
              name: "experience-calendar",
              component: () => import("@/view/pages/experience/Calendar.vue")
            }
          ]
        },
        {
          path: "/reservations",
          name: "reservations",
          component: () => import("@/view/pages/reservation/Reservation.vue"),
          children: [
            {
              path: "listings",
              name: "reservations",
              component: () => import("@/view/pages/reservation/Listings.vue")
            },
            {
              path: "listings/:reservationNumber/:id",
              name: "reservation-details",
              component: () => import("@/view/pages/reservation/Details.vue")
            }
          ]
        },
        {
          path: "/operations",
          name: "operations",
          component: () => import("@/view/pages/operation/Operation.vue"),
          children: [
            {
              path: "listings",
              name: "operations",
              component: () => import("@/view/pages/operation/Listings.vue")
            },
            {
              path: "listings/:pnr",
              name: "operation-details",
              component: () => import("@/view/pages/operation/Details.vue")
            }
          ]
        },
        {
          path: "/promotion-code",
          name: "promotion-codes",
          component: () => import("@/view/pages/reservation/Reservation.vue"),
          children: [
            {
              path: "create",
              name: "create-promotion-code",
              component: () => import("@/view/pages/promotion-code/Create.vue")
            },
            {
              path: "listings",
              name: "promotion-codes",
              component: () =>
                import("@/view/pages/promotion-code/Listings.vue")
            },
            {
              path: "listings/:code",
              name: "promotion-code-details",
              component: () => import("@/view/pages/promotion-code/Profile.vue")
            }
          ]
        },
        {
          path: "/provide",
          name: "provide",
          component: () => import("@/view/pages/provide/Provide.vue"),
          children: [
            {
              path: "listings",
              name: "provides",
              component: () => import("@/view/pages/provide/Listings.vue")
            },
            {
              path: "create",
              name: "create-provide",
              component: () => import("@/view/pages/provide/Create.vue")
            }
          ]
        },
        {
          path: "/ribbon",
          name: "ribbon",
          component: () => import("@/view/pages/ribbon/Ribbon.vue"),
          children: [
            {
              path: "listings",
              name: "ribbons",
              component: () => import("@/view/pages/ribbon/Listings.vue")
            },
            {
              path: "create",
              name: "create-ribbon",
              component: () => import("@/view/pages/ribbon/Create.vue")
            }
          ]
        },
        {
          path: "/transport-company",
          name: "transport-company",
          component: () => import("@/view/pages/transport-company/TransportCompany.vue"),
          children: [
            {
              path: "create",
              name: "create-transport-company",
              component: () => import("@/view/pages/transport-company/Create.vue")
            },
            {
              path: "edit/:companyId",
              name: "edit-transport-company",
              component: () => import("@/view/pages/transport-company/Edit.vue")
            },
            {
              path: "listings",
              name: "transport-companies",
              component: () => import("@/view/pages/transport-company/Listings.vue")
            }
          ]
        },
        {
          path: "/exchange",
          name: "exchange",
          component: () => import("@/view/pages/exchange/ExchangeSettings.vue")
        },
        {
          path: "/accounting",
          name: "accounting",
          component: () => import("@/view/pages/accounting/Accounting.vue"),
          children: [
            {
              path: "expense-items",
              name: "expense-items-listing",
              component: () =>
                import("@/view/pages/accounting/expense-item/Listings.vue")
            },
            {
              path: "income-items",
              name: "income-items-listing",
              component: () =>
                import("@/view/pages/accounting/income-item/Listings.vue")
            },
            {
              path: "reporting",
              name: "reporting",
              component: () =>
                import("@/view/pages/accounting/reporting/Reporting.vue")
            },
          ]
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/404.vue")
    }
  ]
});
