import moment from "moment";

// action types
export const UPDATE_START_DATE = "updateStartDate";
export const UPDATE_END_DATE = "updateEndDate";
export const UPDATE_THEMES = "updateThemes";
export const UPDATE_SERVICE_TYPES = "updateServiceTypes";

// mutation types
export const SET_START_DATE = "setStartDate";
export const SET_END_DATE = "setEndDate";
export const SET_THEMES = "setThemes";
export const SET_SERVICE_TYPES = "setServiceTypes";

const state = {
  start_date: "",
  end_date: "",
  themes: [],
  service_types: []
};

const getters = {
  startDate() {
    return state.start_date;
  },
  endDate() {
    return state.end_date;
  },
  formattedDateRange() {
    moment.locale("tr");
    const startMY = moment(state.start_date, "YYYY-MM-DD").format("MMM YYYY");
    const startD = moment(state.start_date, "YYYY-MM-DD").format("D");
    const endMY = moment(state.end_date, "YYYY-MM-DD").format("MMM YYYY");
    const endD = moment(state.end_date, "YYYY-MM-DD").format("D");

    if (startMY === endMY) {
      if (startD === endD) {
        return `${moment(state.start_date, "YYYY-MM-DD").format("D MMMM")}`;
      } else {
        return `${startD} - ${moment(state.end_date, "YYYY-MM-DD").format(
          "D MMMM"
        )}`;
      }
    } else {
      return `${moment(state.start_date, "YYYY-MM-DD").format(
        "D MMM"
      )} - ${moment(state.end_date, "YYYY-MM-DD").format("D MMM")}`;
    }
  },
  themes() {
    return state.themes;
  },
  serviceTypes() {
    return state.service_types;
  }
};

const actions = {
  [UPDATE_START_DATE](context, payload) {
    context.commit(SET_START_DATE, payload);
  },
  [UPDATE_END_DATE](context, payload) {
    context.commit(SET_END_DATE, payload);
  },
  [UPDATE_THEMES](context, payload) {
    context.commit(SET_THEMES, payload);
  },
  [UPDATE_SERVICE_TYPES](context, payload) {
    context.commit(SET_SERVICE_TYPES, payload);
  }
};

const mutations = {
  [SET_START_DATE](state, start_date) {
    state.start_date = start_date;
  },
  [SET_END_DATE](state, end_date) {
    state.end_date = end_date;
  },
  [SET_THEMES](state, themes) {
    state.themes = themes;
  },
  [SET_SERVICE_TYPES](state, service_types) {
    state.service_types = service_types;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
