import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import { SET_PERSONAL_INFO } from '@/core/services/store/profile.module';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_PASSWORD = 'updateUser';
export const VERIFY_CODE = 'verifyCode';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_PASSWORD = 'setPassword';
export const SET_ERROR = 'setError';

const state = {
  errors: null,
  verificationCode: '',
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('auth/login.req.php', credentials)
        .then(({ data }) => {
          const personalInfo = {
            photo: 'media/users/300_21.jpg',
            name: data.user.name,
            surname: data.user.surname,
            company_name: 'Zenofon Holidays',
            job: data.user.title,
            email: data.user.email,
            phone: data.user.phone,
            company_site: 'zenofonholidays.com',
          };
          context.commit(SET_PERSONAL_INFO, personalInfo);
          context.commit(SET_AUTH, data);
          resolve(data.user.phone);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_CODE](context, credentials) {
    return new Promise((resolve, reject) => {
      if (state.verificationCode == credentials) {
        resolve();
      } else {
        reject();
      }
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('login', credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (!JwtService.getToken()) {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put('password', password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.verificationCode = data.verificationCode;
    state.user = data.user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
