// action types
export const UPDATE_REPORTING_THEMES_FILTER = "updateReportingThemesFilter";
export const UPDATE_REPORTING_SERVICE_TYPES_FILTER =
  "updateReportingServiceTypesFilter";

// mutation types
export const SET_REPORTING_THEMES_FILTER = "setReportingThemesFilter";
export const SET_REPORTING_SERVICE_TYPES_FILTER =
  "setReportingServiceTypesFilter";

const state = {
  themes: [],
  service_types: []
};

const getters = {
  reportingThemesFilter() {
    return state.themes;
  },
  reportingServiceTypesFilter() {
    return state.service_types;
  }
};

const actions = {
  [UPDATE_REPORTING_THEMES_FILTER](context, payload) {
    context.commit(SET_REPORTING_THEMES_FILTER, payload);
  },
  [UPDATE_REPORTING_SERVICE_TYPES_FILTER](context, payload) {
    context.commit(SET_REPORTING_SERVICE_TYPES_FILTER, payload);
  }
};

const mutations = {
  [SET_REPORTING_THEMES_FILTER](state, themes) {
    state.themes = themes;
  },
  [SET_REPORTING_SERVICE_TYPES_FILTER](state, service_types) {
    state.service_types = service_types;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
